import React, { useRef, useState } from "react";
import SlideOne from "./slides/SlideOne";
import SlideTwo from "./slides/SlideTwo";
import SlideThree from "./slides/SlideThree";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';

function Sliders() {
    const swiperRef = useRef(null);
    const [activeSlide, setActiveSlide] = useState(0); // State to track the active slide

    const goToSlide = (index) => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideTo(index);
        }
    };

    const handleSlideChange = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            setActiveSlide(swiperRef.current.swiper.realIndex); // Update active slide
        }
    };

    return (
        <Swiper
            ref={swiperRef}
            spaceBetween={0}
            slidesPerView={1}
            loop={false}
            pagination={false} // Turn off default pagination
            onSlideChange={handleSlideChange} // Track slide change
        >
            <SwiperSlide>
                <SlideOne prev={() => goToSlide(2)} next={() => goToSlide(1)} />
            </SwiperSlide>
            <SwiperSlide>
                <SlideTwo prev={() => goToSlide(0)} next={() => goToSlide(2)} />
            </SwiperSlide>
            <SwiperSlide>
                <SlideThree prev={() => goToSlide(1)} next={() => goToSlide(0)} />
            </SwiperSlide>

            {/* Custom pagination dots */}
            <div className="flex flex-row space-x-2 absolute z-10 bottom-0 w-full justify-center items-center p-4">
                {[0, 1, 2].map((index) => (
                    <button
                        key={index}
                        onClick={() => goToSlide(index)}
                        className={`w-3 h-3 rounded-full ${activeSlide === index ? 'bg-white' : 'bg-white opacity-50'} hover:bg-[#171717]`}
                    />
                ))}
            </div>
        </Swiper>
    );
}

export default Sliders;
