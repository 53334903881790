import { useNavigate } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import { useState, useEffect } from "react";
import Menu from "./modal/Menu";
import { Link } from "react-router-dom";

function Navbar({ color }) {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [bgColor, setBgColor] = useState(color);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setBgColor("rgba(16, 16, 16, 1)");
            } else {
                setBgColor(color);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup event listener on component unmount
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div
            className={`fixed z-10 mobile:p-2 laptop:p-6 mobile:px-6 laptop:px-12 flex flex-row justify-between w-full items-center transition-colors duration-300`}
            style={{ backgroundColor: bgColor }}
        >
            <Link to="/" className="hover:opacity-50">
                <img loading="eager" className="h-8" src={require("../../images/logo_firemans.png")} alt="logo" />
            </Link>
            <div className="flex flex-row items-center space-x-8 mobile:hidden laptop:block">
                <button onClick={() => navigate("/rent")} className="hover:opacity-50">
                    <p className="desktop:text-lg tablet:text-xs text-white font-semibold font-inter">Аренда транспорта</p>
                </button>
                <button onClick={() => navigate('/shop')} className="hover:opacity-50">
                    <p className="desktop:text-lg tablet:text-xs text-white font-semibold font-inter">Поставка запчастей и оборудования</p>
                </button>
                <button onClick={() => navigate("/education")} className="hover:opacity-50">
                    <p className="desktop:text-lg tablet:text-xs text-white font-semibold font-inter">Обучение</p>
                </button>
                <button
                    onClick={() => {
                        const aboutUsSection = document.getElementById("about-us");
                        if (aboutUsSection) {
                            aboutUsSection.scrollIntoView({ behavior: "smooth" });
                        }
                    }}
                    className="hover:opacity-50"
                >
                    <p className="desktop:text-lg tablet:text-xs text-white font-semibold font-inter">
                        О нас
                    </p>
                </button>

                <button onClick={() => navigate('/')} className="hover:opacity-50">
                    <p className="desktop:text-lg tablet:text-xs text-white font-semibold font-inter">Главная</p>
                </button>
                <button onClick={() => navigate("/blog")} className="hover:opacity-50">
                    <p className="desktop:text-lg tablet:text-xs text-white font-semibold font-inter">Блог</p>
                </button>
                <button onClick={() => navigate('/contacts')} className="hover:opacity-50">
                    <p className="desktop:text-lg tablet:text-xs text-white font-semibold font-inter">Контакты</p>
                </button>
            </div>
            <p className="desktop:text-lg tablet:text-xs text-white font-semibold hover:opacity-50 mobile:hidden laptop:block font-inter">
                <a
                    href="https://wa.me/77025111511"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:underline"
                >
                    +7 (702) 511-15-11
                </a>
            </p>
            <button onClick={() => setIsOpen(true)} className="hover:opacity-50 mobile:block laptop:hidden">
                <FiMenu className="text-3xl text-white" />
            </button>
            {isOpen && <Menu onClose={() => setIsOpen(false)} />}
        </div>
    );
}

export default Navbar;
