import Navbar from "../ux/Navbar";
import AboutUs from "../ux/AbousUs";
import { IoIosArrowForward } from "react-icons/io";
import OurAdvantages from "../ux/OurAdvantages";
import Education from "../ux/Education";
import Rent from "../ux/Rent";
import Form from "../ux/Form";
import Footer from "../ux/Footer";
import { useNavigate } from "react-router-dom";
import ParallaxSection from "../ux/ParallaxEffect";
import { useEffect } from "react";
import Sliders from "../ux/Slider";

function MainPage() {
    const navigate = useNavigate();

    useEffect(() => {
        // Прокручивает страницу к началу при монтировании компонента
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='w-full h-screen'>
            <Navbar color="transparent" />
            <Sliders />
            <AboutUs />
            <ParallaxSection>
                <div className="desktop:h-[500px] desktop2:h-[650px] flex flex-row space-x-6 border-b-2 py-12 mx-12 mobile:hidden laptop:flex">
                    <div className="flex flex-col h-full flex-1 space-y-4">
                        <p className="font-bold text-[#171717] font-neutral text-3xl ">Транспорт по мере вашего специфического<br /> запроса</p>
                        <p className="text-2xl w-full text-[#171717] font-inter font-light">Предлагаем широкий спектр специализированного транспорта для любых ваших нужд. Наша компания предоставляет современные и надежные машины для выполнения разнообразных задач, будь то строительство, логистика, аварийно-спасательные работы или сельское хозяйство. Весь наш транспорт находится в отличном техническом состоянии и полностью готов к эксплуатации.</p>
                        <button onClick={() => navigate("/rent")} className="bg-white border-[1px] border-[#171717] self-start p-2 px-4 flex flex-row align-baseline space-x-4 items-center group hover:bg-[#171717] ease-out duration-500">
                            <p className="text-[#171717] font-inter text-xl group-hover:text-white">Узнать больше</p>
                            <div className="w-10 h-10 bg-[#171717] flex justify-center items-center group-hover:bg-[#171717]">
                                <IoIosArrowForward className="text-xl text-white text-inter" />
                            </div>
                        </button>
                    </div>
                    <div>
                        <img loading="eager" src={require("../../images/fire_engine.png")} className="h-full" alt="fire_engine" />
                    </div>
                </div>
            </ParallaxSection>
            <ParallaxSection>
                <div className="py-12 border-b-2 mx-6 mobile:block laptop:hidden">
                    <p className="font-bold text-[#171717] font-neutral w-full text-2xl ">Транспорт по<br /> мере вашего<br /> специфического<br /> запроса</p>
                    <img loading="eager" src={require("../../images/fire_engine.png")} className="h-[300px] my-4 w-full object-cover" alt="fire_engine" />
                    <p className="text-2xl text-[#171717] font-inter mt-4 font-light">Предлагаем широкий спектр специализированного транспорта для любых ваших нужд. Наша компания предоставляет современные и надежные машины для выполнения разнообразных задач, будь то строительство, логистика, аварийно-спасательные работы или сельское хозяйство. Весь наш транспорт находится в отличном техническом состоянии и полностью готов к эксплуатации.</p>
                    <button onClick={() => navigate("/rent")} className="bg-white border-[1px] self-start border-[#171717] p-2 px-4 flex flex-row space-x-4 items-center group hover:bg-[#171717] ease-out duration-500">
                        <p className="text-[#171717] font-inter text-xl group-hover:text-white">Узнать больше</p>
                        <div className="w-10 h-10 bg-[#171717] flex justify-center items-center group-hover:bg-[#171717]">
                            <IoIosArrowForward className="text-xl text-white text-inter" />
                        </div>
                    </button>
                </div>
            </ParallaxSection>
            <ParallaxSection>
                <div className="desktop:h-[500px] desktop2:h-[650px] flex flex-row space-x-6 border-b-2 py-12 mx-12 mobile:hidden laptop:flex">
                    <div>
                        <img loading="eager" src={require("../../images/image2.png")} className="h-full" alt="fire_engine" />
                    </div>
                    <div className="flex flex-col h-full flex-1 space-y-4">
                        <p className="font-bold text-[#171717] font-neutral text-3xl">ГЛУБОКОЕ ПОГРУЖЕНИЕ: СПЕЦИАЛИЗИРОВАННЫЕ КУРСЫ ДЛЯ ПРОФЕССИОНАЛОВ</p>
                        <p className="text-2xl w-full text-[#171717] font-inter font-light">Наши специализированные курсы предлагают глубокое погружение в ключевые аспекты вашей профессиональной сферы, обеспечивая практические навыки и экспертное знание для достижения высоких результатов.</p>
                        <button onClick={() => navigate("/rent")} className="bg-white self-start border-[1px] border-[#171717] p-2 px-4 flex flex-row space-x-4 items-center group hover:bg-[#171717] ease-out duration-500">
                            <p className="text-[#171717] font-inter text-xl group-hover:text-white">Узнать больше</p>
                            <div className="w-10 h-10 bg-[#171717] flex justify-center items-center group-hover:bg-[#171717]">
                                <IoIosArrowForward className="text-xl text-white text-inter" />
                            </div>
                        </button>
                    </div>
                </div>
            </ParallaxSection>
            <ParallaxSection>
                <div className="py-12 border-b-2 mx-6 mobile:block laptop:hidden">
                    <p className="font-bold text-[#171717] font-neutral w-full text-2xl ">Глубокое погружение: Специализированные курсы для профессионалов</p>
                    <img loading="eager" src={require("../../images/image2.png")} className="h-[300px] my-4 w-full object-cover" alt="fire_engine" />
                    <p className="text-2xl text-[#171717] font-inter mt-4 font-light">Наши специализированные курсы предлагают глубокое погружение в ключевые аспекты вашей профессиональной сферы, обеспечивая практические навыки и экспертное знание для достижения высоких результатов.</p>
                    <button onClick={() => navigate("/rent")} className="bg-white border-[1px] border-[#171717] p-2 px-4 flex flex-row space-x-4 items-center group hover:bg-[#171717] ease-out duration-500">
                        <p className="text-[#171717] font-inter text-xl group-hover:text-white">Узнать больше</p>
                        <div className="w-10 h-10 bg-[#171717] flex justify-center items-center group-hover:bg-[#171717]">
                            <IoIosArrowForward className="text-xl text-white text-inter" />
                        </div>
                    </button>
                </div>
            </ParallaxSection>


            <ParallaxSection>
                <div className="desktop:h-[500px] desktop2:h-[650px] flex flex-row space-x-6 border-b-2 py-12 mx-12 mobile:hidden laptop:flex">
                    <div className="flex flex-col space-y-4 h-full flex-1">
                        <p className="font-bold text-[#171717] font-neutral text-3xl ">Надёжные поставки для безопасности и эффективности</p>
                        <p className="text-2xl w-full text-[#171717] font-inter font-light">Мы специализируемся на поставке высококачественного аварийно-спасательного и противопожарного оборудования, средств для создания пены и автозапчастей, обеспечивая надежную защиту и безопасность в критических ситуациях.</p>
                        <button onClick={() => navigate("/rent")} className="bg-white border-[1px] self-start border-[#171717] p-2 px-4 flex flex-row space-x-4 items-center group hover:bg-[#171717] ease-out duration-500">
                            <p className="text-[#171717] font-inter text-xl group-hover:text-white">Узнать больше</p>
                            <div className="w-10 h-10 bg-[#171717] flex justify-center items-center group-hover:bg-[#171717]">
                                <IoIosArrowForward className="text-xl text-white text-inter" />
                            </div>
                        </button>
                    </div>
                    <div>
                        <img loading="eager" src={require("../../images/image3.png")} className="h-full" alt="fire_engine" />
                    </div>
                </div>
            </ParallaxSection>
            <ParallaxSection>
                <div className="py-12 border-b-2 mx-6 mobile:block laptop:hidden">
                    <p className="font-bold text-[#171717] font-neutral w-full text-2xl ">Глубокое погружение: Специализированные курсы для профессионалов</p>
                    <img loading="eager" src={require("../../images/image3.png")} className="h-[300px] my-4 w-full object-cover" alt="fire_engine" />
                    <p className="text-2xl text-[#171717] font-inter mt-4 font-light">Наши специализированные курсы предлагают глубокое погружение в ключевые аспекты вашей профессиональной сферы, обеспечивая практические навыки и экспертное знание для достижения высоких результатов.</p>
                    <button onClick={() => navigate("/rent")} className="bg-white border-[1px] border-[#171717] p-2 px-4 flex flex-row space-x-4 items-center group hover:bg-[#171717] ease-out duration-500">
                        <p className="text-[#171717] font-inter text-xl group-hover:text-white">Узнать больше</p>
                        <div className="w-10 h-10 bg-[#171717] flex justify-center items-center group-hover:bg-[#171717]">
                            <IoIosArrowForward className="text-xl text-white text-inter" />
                        </div>
                    </button>
                </div>
                {/*  */}
            </ParallaxSection>
            <ParallaxSection>
                <OurAdvantages />
            </ParallaxSection>
            <ParallaxSection>
                <Education />
            </ParallaxSection>
            <ParallaxSection>
                <Rent />
            </ParallaxSection>
            <ParallaxSection>
                <Form />
            </ParallaxSection>
            <Footer />
        </div>
    )
};

export default MainPage;