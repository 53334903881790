import { FaWhatsapp } from "react-icons/fa6";

function WhatsApp() {
  return (
    <a
      href="https://wa.me/77025111511"
      target="_blank"
      rel="noopener noreferrer"
      className="bg-[#171717] group hover:bg-[#2BE862] w-16 h-16 fixed z-[100] rounded-full right-12 bottom-12 flex justify-center items-center"
    >
      <FaWhatsapp className="text-white text-3xl" />
    </a>
  )
};

export default WhatsApp;